.container {
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: hidden;
}

.main {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  background: #eee;
}

.sideBar {
  background: white;
  overflow: scroll;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 252px;
  flex: 0 0 252px;
  border-right: 1px solid #edebe9;

  @media (min-width: 992px) {
    flex: 0 0 252px;
  }
}

.hideSideBar {
  @media (max-width: calc(992px - 1px)) {
    display: none;
  }
}

.content {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0px 0px;
  padding-top: 2px;
  overflow: hidden;
}

.tabContent {
  overflow: auto;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  background: white;
  position: relative;
  display: flex;
}

.tabContent[aria-hidden='true'] {
  display: none;
}

.tabTitleContainer {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.tabPill {
  position: relative !important;
  padding: 5px 2rem 5px 1.5rem !important;
  user-select: none;

  /* &:first-child {
    border-top-left-radius: 0px;
  } */
}

@media (max-width: calc(769px - 1px)) {
  .tabPill {
    width: 200px;
  }
}

.tabPill:first-child {
  border-top-left-radius: 0px;
}

.navigationContent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column wrap;
  flex-flow: column wrap;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  height: 100%;
}

.callout {
  width: 200px;
}

.tabButton {
  width: 100%;
  border: 0;
}

.closeButton {
  width: 100%;
  border: 0;
  color: red;
}