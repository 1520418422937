@value inactive: #EFEFEF;
@value active: #FFFFFF;
@value inactive-text:#868E96;
@value active-text:#2C2E33;
@value font-family:"Inter";

.tabs__container {
  width: 100%;
  display: flex;
}

.tabs__tab {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  align-items: center;
  font-size: 12px;
  padding: 4px 12px;
  font-family: font-family;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  background-color: red;
}

.tabs__tab--active {
  background-color: active;
  color: active-text;
}

.tabs__tab--inactive {
  background-color: inactive;
  color: inactive-text;
}

.tabs__button {
  border: none;
  background-color: transparent;
  margin-top: 2px;
  margin-left: 8px;
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
}

.tabs__button:hover {
  background-color: #C7C7C780;
}

.tab__separator {
  display: flex;
  align-items: center;
}

.separator--active {
  border-right: 1px solid #CED4DA;
}

.separator--inactive {
  height: calc(100% - 4px) !important;
  visibility: hidden;
}

.tab__showmore {
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.tab__showmore_container {
  position: absolute;
  flex-direction: column;
  background-color: white;
  z-index: 2;
  top: 30px;
  right: 30px;
  width: fit-content;
  box-shadow: 0 0 10px #00000050;
  border-radius: 8px;
  overflow: hidden;
}

.tab__invisibleTab {
  z-index: -2;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.tab__showmore_item {
  border-radius: 0;
}

.tab_item_title {
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: font-family;
  display: flex;
  align-items: center;

}